.university-container .grid-university {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: 100px;
    /* Set a fixed height for each row (e.g., 100px) */
    gap: 20px;
    align-content: stretch;
    /* Add this property */
}

.country {
    margin-bottom: 6rem;
}


.grid-university a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    max-height: 100%;
    overflow: hidden;
    /* Add this to handle image overflow */
}

.grid-university a:hover {
    border: 2px solid #063970;
}

.grid-university img {
    max-width: auto;
    max-height: 100%;
    object-fit: contain;
    /* To keep image aspect ratio while fitting inside the container */
}