.image-gallery{
    display: flex;
    flex-direction: column;
  justify-content: center; /* This will horizontally center the child elements (the img) */
  align-items: center;
  gap:2rem;
}

.gallery-container{
    display: flex;
    justify-content: center; /* This will horizontally center the child elements (the img) */
  align-items: center;
}
.image-gallery img {
    width: 70%; /* Make the image fill the container */
    height: auto; /* Keep the aspect ratio */
    transition: opacity 0.5s ease-in-out; /* Smooth transition for image change */
  }

  .image-gallery button {
    background: none;
    border: none;
    color: #333; /* Customize the color based on your design */
    font-size: xx-large; /* Adjust size as needed */
    padding: 10px; /* Padding for clickable area */
    cursor: pointer;
  }
  .dots span {
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: grey;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .dots span.active {
    background-color: #1c2f4c;
  }