.contact {
    overflow-x: hidden;
    /* Hide horizontal overflow */
}
#contactus {
    display: table;
    width: 100%;
    height: 100vh;
    background: url(../../../public/img/world_map3.png) top center;
    background-size: cover;
}

#contactus .content-container {
    background: rgba(0, 0, 0, 0.4);
    height: 100vh;
}

#contactus .content-container .griditem {
    align-self: flex-start;
}
#contactus h1 {
    padding: 2rem;
        padding-bottom: 0;
    color: whitesmoke;
    top: 50%;
    left: 50%;
    font-size: 3rem;
    font-weight: bold;
    
}

#contactus .contact-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
        place-items: center;
    margin: 0;
        padding: 0;
        padding-top: 6rem;
        text-align: center;
        vertical-align: middle;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        padding-top: 0;
            /* Set a single column for smaller screens */
        }
}
@media (min-width:1024px) {
    #contactus {
        background-attachment: fixed;
    }
}
.icon-outter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.circle {
    background-color: transparent;
    border: 2px solid #A9A9A9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 9rem;
    height: 9rem;

}

.circle-inner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Center-align items horizontally */
    text-align: center;
    /* Center-align text inside paragraphs */
}
.content-wrap p {
    color: white;
}
.office {
    font-size: xx-large;
    font-weight: bolder;
    color: whitesmoke;
}

.email {
    font-size: x-large;
}

.email-link {
    text-decoration: none;
    color: whitesmoke;
    margin-left: 5px;
}

.email-link:hover {
    text-decoration: underline;
}