/* description*/
.descrip .title {
    text-align: start;
}
.descrip .title span {
    margin-right: 2rem;
}

.right li span {
    color: #B8860B;
    font-weight: bold;
}

/* outline */
.togglebutton {
    border: none;
    width: auto;
    padding: 15px 0;
        word-wrap: break-word;
}

/* assessment*/
.flex-container{
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers children along the main axis (vertically in this case) */
    align-items: center; 
}

.flex-img{
    width: 15%;
    height: auto;
}

/* photo-gallery */
.photo-gallery-container p,blockquote{
    color:rgb(132,129,129);
}



.course .row {
    display: grid;
    grid-template-columns: 3fr 2fr;
    place-content: center;
}

@media (max-width: 992px) {
    .course .row {
        display: flex;
        flex-direction: column;
    }
}
.course .line2 {
    border-top: 3px solid lightslategray;
    padding-top: 10px;
}

.course h3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.course .right li {
    margin-bottom: 1rem;
}

/* Expectation */
.expectation {
    margin-bottom: 2rem;
    margin-top: 2rem;
}



.expectation h2 {
    text-align: center;
    color: whitesmoke;
    margin-bottom: 2rem;
}


.detail-list {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
        align-items: center;
}

.detail-list button {
    border-radius: 15px;
        width: 100%;
    text-align: left;
    margin: 1px;
    white-space: normal;
    font-weight: 600;
        line-height: 1.5;
}



/* strategy */

.strategy-content {
    margin-bottom: 2rem;
}

/* grade */
.grade {
    margin-bottom: 2rem;
}


/* Report */

.grid-container {
    display: grid;
    grid-template-rows: auto auto;
        grid-template-columns: 1fr 2fr;
    margin-bottom: 2rem;
    gap: 3rem;
}

@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr;
        /* Set a single column for smaller screens */
    }
}



/* Plan */
.grid-container-two-equal-col {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
        gap: 2rem;
        justify-content: space-around;
}

@media (max-width: 768px) {
    .grid-container-two-equal-col {
        grid-template-columns: 1fr;
        /* Set a single column for smaller screens */
    }
}
.grid-col {
    border: 2px solid #063970;
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem;
}

.others-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1rem;
}

.others-list button {
    border-radius: 3px;
    width: max-content;
}

.other-content {
    padding-bottom: 2rem;
}