.grade-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 3rem;
        padding-left: 10%;
        padding-right: 10%;
    }
    
    .grid-item {
        border: 1px solid lightgray;
        /* Add a 1px solid black border to each grid item */
        padding: 10px;
        /* Optional padding to create space inside each item */
        text-align: center;
        /* Optional alignment of content */
}