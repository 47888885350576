.tab-content li {
    list-style-type: circle;
    margin: 0.5em 0;
}

.tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1rem;
    margin-top: 1rem;

}

@media (max-width:992px) {
    .tabs {
        flex-wrap: wrap;
    }
}