.Nav {
    background-color: #0f3f3f;
    position: relative;
    border: 1px solid lightslategray;
}

.Nav .nav-items {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    padding: 1rem 1rem 1rem 1rem;
        color: white;
        font-size: x-large;
}
.Nav ul li a {
    text-decoration: none;
    color: white;
    font-weight: bolder;
}

.Nav ul li a:hover {
    color: bisque;
}

.Nav .dropdown-container {
    position: relative;
}

.Nav .dropdown-container .dropdown-button {
    transition: background-color 0.3s;
}


.Nav .dropdown-container .dropdown-content {
    /* display: none; */
    position: absolute;
    width: fit-content;
        white-space: nowrap;
        /* Prevent text from wrapping */
    top: 100%;
    left: 0;
    background-color: #0f3f3f;
    list-style-type: none;
    z-index: 999;
    box-sizing: border-box;
    text-align: left;
    margin: 0;
    padding: 1rem;
}

/* 每个下拉项样式 */
.Nav .dropdown-container .dropdown-content li {
    margin: 5px 0;
    /* 每个下拉项的上下间距 */
    display: block;
    /* 让每个li元素独占一行 */
    width: 100%;
    font-size: 1.5rem;
    
}

/* 下拉菜单项链接样式 */
.Nav .dropdown-container .dropdown-content a {
    color: white;
    /* 文字颜色 */
    text-decoration: none;
    /* 移除链接下划线 */
    font-size: 1rem;
}

/* 当鼠标悬停在下拉菜单项链接上时的样式 */
.Nav .dropdown-container .dropdown-content a:hover {
    text-decoration: underline;
    color: beige
    /* 鼠标悬停时添加下划线 */
}
/* sub menu */
@media (max-width:768px) {
    .Nav li {
        flex: 0 0 calc(33.33% - 20px);
        /* Each row should have 3 items (100% / 3) */
        /* 20px is the margin space, adjust it based on your design */
        max-width: calc(33.33% - 20px);
        /* Limit the maximum width of each item */
    }

    .Nav .dropdown-container .dropdown-content {
        padding: 0.3rem;
        width: max-content;

    }
}