@keyframes flipInX {
    0% {
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotateX(-10deg);
        opacity: 0.3;
    }

    70% {
        transform: perspective(400px) rotateX(10deg);
        opacity: 0.6;
    }

    100% {
        transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}


.word {
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
}

.flip-in-x {
    animation-name: flipInX;
}