.image-text-container {


    /* Add spacing between image and text */
    margin: 20px;
}

.image {
    max-width: 100%;
    /* Adjust the image size as needed */
    margin-right: 20px;
    /* Add margin to separate image and text */
    width: 40%;
    /* Float the image to the left */
}

.text {
    /* Style your text as needed */
}