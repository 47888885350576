#joinus {
    display: table;
    width: 100%;
    height: 100vh;
    background: url(../../../public/img/hiring2.jpg) top center;
    background-size: cover;
}

#joinus .joinus-container {
    background: rgba(0, 0, 0, 0.4);
    display: table-cell;
    margin: 0;
    padding: 0;
    text-align: center;
    vertical-align: middle;
}

@media (min-width:1024px) {
    #joinus {
        background-attachment: fixed;
    }
}

#joinus .wea-logo {
    width: 5rem;
    height: auto;
    margin: 2rem;
    transition: transform 0.3s ease;
}

#joinus .wea-logo:hover {
    transform: scale(1.2);
}
.context {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.context h2 {
    font-size: 5rem;
    color: whitesmoke;
}

#joinus .context p,
#joinus .context a {
    color: whitesmoke;
    font-weight: bolder;
    font-size: 1rem;
}

.text-container {
    text-align: center;
    padding: 5rem;
    margin: 1rem;
    border: 3px solid lightgrey;
    /* White outline */
}

.three-line-text {
    line-height: 1.5;
    /* Adjust the line height as needed */
}

@media (max-width:768px) {
    .text-container {
        padding: 1rem;
    }

    .context h2 {
        font-size: 3rem;
    }
}