#hero {
    display: table;
    width: 100%;
    height: 100vh;
    background: url(../../../public/img/background.webp) center;
    background-size: cover;
}

#hero .hero-container {
    background: rgba(0, 0, 0, 0.4);
    display: table-cell;
    margin: 0;
    padding: 0;
    text-align: center;
    vertical-align: middle;
}

#hero .hero-logo {
    width: 300px;
    margin: auto;
}

#hero .hero-logo img {
    max-width: 100%;
}

#hero h1 {
    margin: 30px 0 10px 0;
    font-weight: 700;
    line-height: 48px;
    text-transform: uppercase;
    color: #fff;
}

#hero h2 {
    color: #eeaf4e;
    margin-bottom: 50px;
}

#hero .word-changer span {
    font-size: 35px;
    font-weight: 400;
    font-family: cursive;
}

#hero .actions a {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 20px;
    border-radius: 2px;
    transition: 0.5s;
    margin: 10px;
}

#hero .btn-get-started {
    border: 2px solid #fff;
    color: #fff;
}

#hero .btn-get-started:hover {
    background-color: #eeaf4e;
}

#hero .btn-contact:hover {
    background-color: #eeaf4e;
}

#hero .btn-contact {
    border: 2px solid #fff;
    color: #fff;
}

.Intro {
    display: flex;
    flex-direction: column;
    padding: 1% 30% 1% 30%;
}

.Intro img {
    width: 50%;
    height: auto;
    margin: 0 10% 0 25%;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Optional: If you want the image to take full height */
}







@media (min-width:1024px) {
    #hero {
        background-attachment: fixed;
    }
}