.grade-course {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: 100%;
        height: auto;
    }
    
                                                                .inbox-grid-wrapper {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0.7rem;
    }
    
    .grade-course .col-left img {
        width: 85px;
        border-radius: 10px;
    }
    
    .grade-course .col-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0.5rem;
    padding-left: 1rem;
    }
    
    .grade-course .col-right p {
        text-align: left;
}

.view-details {
    justify-self: end;
    align-self: end;
}
.grade-course a {
    text-decoration: none;
    color: #a2a2a2;
}

.grade-course .descrip .left img {
    width: 144px;
    margin-bottom: 0.2rem;

    @media(max-width:768px) {
        width: 72px;
    }
}
.grade-course a:hover {
    text-decoration: underline;
    color: #0f3f3f;
}
/* font */

/* outline */
.outline h4 {
    color: #0f3f3f;
    font-size: large;
    text-align: center;
}



/* expectation */

/* strategy */

/* consideration */
.plan-title {
    color: #6B8E23;
    font-weight: bold;
    font-size: large;
}

.multi-col {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
    grid-column-gap: 20px;
    margin: 2rem 0;
}

.col-box {
    border: 2px solid black;
        box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
        ;
    padding: 1rem;
    margin-bottom: 1rem;
}
