.two-col-grid {
    display: grid;
    grid-template-rows: auto auto;
    /* Two rows with auto height */
    grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
    /* Three columns with equal width */
    /* Gap between grid items */
    padding: 1rem;
    justify-content: center;
    /* Center items horizontally */
    align-items: center;
    /* Center items vertically */
}

.grid-img {
    display: grid;
        place-items: center;
        /* 水平和垂直居中 */
    max-width: 100%;
    height: auto;
    padding: 0 10% 0 10%;
    overflow: hidden;
        text-align: center;
        height: 100%;
}

.grid-img img {
    object-fit: cover;
        border-radius: 30px;
            max-height: 100%;
            max-width: 100%;
            height: auto;
}



.grid-text h2 {
    text-align: center;
}

.grid-text {
    padding: 0 10% 0 10%;
}



.grid-text p {
    color: rgb(132, 129, 129);
}

.grid-text li {
    list-style-type: circle;
}
@media (min-width:1024px) {
    .two-col-grid {
            grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
    }

                .grid-img {
                    grid-row: 1;
                }
        
                .grid-text {
                    grid-row: 1;
                }
    .text-reversed {
        grid-row: 1;
        grid-column: 1;
    }

    .img-reversed {
        grid-row: 1;
        grid-column: 2;
    }
}