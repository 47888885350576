.admission .divider {
    margin: 20px 0;
}
.admission h2 {
    text-align: start;
}
.img-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 2rem 2rem 2rem 2rem;
}

.admission img {
    max-width: 100%;
    height: auto;
}

.grid-wrapper h3 {
    font-size: xx-large;
    margin-bottom: 2rem;
    color: #0f3f3f;
}

.apply-email {
    font-weight: bolder;
    font-size: large;
    color: #0f3f3f;
}

.refund {
    margin-top: 2rem;
}
